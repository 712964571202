import { Grid, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { FC } from 'react';

const SignaturePackageListToolbar: FC= () => {

  return (
    <GridToolbarContainer>
      <Grid container mb={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" fontWeight="bold">
            Listado de compras de paquetes de firmas realizadas
          </Typography>
          <Typography variant="body2">
            Aquí podrás visualizar todos los paquetes de firmas que has comprado y completado.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default SignaturePackageListToolbar;