export enum Roles {
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export enum Permissions {
  VIEW_HOME = 'VIEW_HOME',
  MANAGE_ROLES = 'MANAGE_ROLES',
  LIST_ROLES = 'LIST_ROLES',
  CREATE_ROLE = 'CREATE_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  LIST_PERMISSIONS = 'LIST_PERMISSIONS',
  CREATE_PERMISSION = 'CREATE_PERMISSION',
  VIEW_PERMISSION = 'VIEW_PERMISSION',
  UPDATE_PERMISSION = 'UPDATE_PERMISSION',
  DELETE_PERMISSION = 'DELETE_PERMISSION',
  MANAGE_SIGNATURE_INVENTORY = 'MANAGE_SIGNATURE_INVENTORY',
  LIST_SIGNATURE_INVENTORY = 'LIST_SIGNATURE_INVENTORY',
  CREATE_SIGNATURE_INVENTORY = 'CREATE_SIGNATURE_INVENTORY',
  VIEW_SIGNATURE_INVENTORY = 'VIEW_SIGNATURE_INVENTORY',
  UPDATE_SIGNATURE_INVENTORY = 'UPDATE_SIGNATURE_INVENTORY',
  DELETE_SIGNATURE_INVENTORY = 'DELETE_SIGNATURE_INVENTORY',
  INCREMENT_SIGNATURE_INVENTORY = 'INCREMENT_SIGNATURE_INVENTORY',
  DECREMENT_SIGNATURE_INVENTORY = 'DECREMENT_SIGNATURE_INVENTORY',
  MANAGE_SIGNATURE_PACKAGES = 'MANAGE_SIGNATURE_PACKAGES',
  LIST_SIGNATURE_PACKAGES = 'LIST_SIGNATURE_PACKAGES',
  CREATE_SIGNATURE_PACKAGE = 'CREATE_SIGNATURE_PACKAGE',
  VIEW_SIGNATURE_PACKAGE = 'VIEW_SIGNATURE_PACKAGE',
  MANAGE_COUPONS = 'MANAGE_COUPONS',
  LIST_COUPONS = 'LIST_COUPONS',
  CREATE_COUPON = 'CREATE_COUPON',
  VIEW_COUPON = 'VIEW_COUPON',
  MANAGE_DOCUMENT_TYPES = 'MANAGE_DOCUMENT_TYPES',
  LIST_DOCUMENT_TYPES = 'LIST_DOCUMENT_TYPES',
  CREATE_DOCUMENT_TYPE = 'CREATE_DOCUMENT_TYPE',
  VIEW_DOCUMENT_TYPE = 'VIEW_DOCUMENT_TYPE',
  MANAGE_DOCUMENT_TYPE_FEES = 'MANAGE_DOCUMENT_TYPE_FEES',
  LIST_DOCUMENT_TYPE_FEES = 'LIST_DOCUMENT_TYPE_FEES',
  CREATE_DOCUMENT_TYPE_FEE = 'CREATE_DOCUMENT_TYPE_FEE',
  VIEW_DOCUMENT_TYPE_FEE = 'VIEW_DOCUMENT_TYPE_FEE',
  MANAGE_USERS = 'MANAGE_USERS',
  LIST_USERS = 'LIST_USERS',
  CREATE_USER = 'CREATE_USER',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD',
  VIEW_USER_PROFILE = 'VIEW_USER_PROFILE',
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  MANAGE_DOCUMENTS = 'MANAGE_DOCUMENTS',
  LIST_DOCUMENTS = 'LIST_DOCUMENTS',
  VIEW_DOCUMENT = 'VIEW_DOCUMENT',
  DOWNLOAD_DOCUMENT_FILE = 'DOWNLOAD_DOCUMENT_FILE',
  MANAGE_USER_DOCUMENTS = 'MANAGE_USER_DOCUMENTS',
  LIST_USER_DOCUMENTS = 'LIST_USER_DOCUMENTS',
  CREATE_USER_DOCUMENT = 'CREATE_USER_DOCUMENT',
  VIEW_USER_DOCUMENT = 'VIEW_USER_DOCUMENT',
  UPDATE_USER_DOCUMENT = 'UPDATE_USER_DOCUMENT',
  DELETE_USER_DOCUMENT = 'DELETE_USER_DOCUMENT',
  SIGN_USER_DOCUMENT = 'SIGN_USER_DOCUMENT',
  PAY_USER_DOCUMENT_SIGNATURES = 'PAY_USER_DOCUMENT_SIGNATURES',
  MANAGE_SIGNATURE_CREDITS = 'MANAGE_SIGNATURE_CREDITS',
  LIST_SIGNATURE_CREDITS = 'LIST_SIGNATURE_CREDITS',
  LIST_SIGNATURE_CREDITS_PURCHASES = 'LIST_SIGNATURE_CREDITS_PURCHASES',
  PURCHASE_USER_SIGNATURE_CREDITS = 'PURCHASE_USER_SIGNATURE_CREDITS',
  PAY_USER_SIGNATURE_CREDITS = 'PAY_USER_SIGNATURE_CREDITS',
  MANAGE_FILES = 'MANAGE_FILES',
  LIST_FILES = 'LIST_FILES',
  CREATE_FILE = 'CREATE_FILE',
  VIEW_FILE = 'VIEW_FILE',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  DOWNLOAD_USER_DOCUMENT_FILE = 'DOWNLOAD_USER_DOCUMENT_FILE',
  DOWNLOAD_USER_BIOMETRY_FILES = 'DOWNLOAD_USER_BIOMETRY_FILES',
  UPLOAD_USER_DOCUMENT_FILE = 'UPLOAD_USER_DOCUMENT_FILE',
  MANAGE_PAYMENTS = 'MANAGE_PAYMENTS',
  LIST_PAYMENTS = 'LIST_PAYMENTS',
  MANAGE_BIOMETRIC_VALIDATIONS = 'MANAGE_BIOMETRIC_VALIDATIONS',
  LIST_BIOMETRIC_VALIDATIONS = 'LIST_BIOMETRIC_VALIDATIONS',
  FORWARD_USER_BIOMETRIC_VALIDATION_EMAIL = 'FORWARD_USER_BIOMETRIC_VALIDATION_EMAIL',
  MANAGE_SIGNATURES = 'MANAGE_SIGNATURES',
  LIST_SIGNATURES = 'LIST_SIGNATURES',
  MANAGE_USER_SIGNATURE_CREDITS = 'MANAGE_USER_SIGNATURE_CREDITS',
}