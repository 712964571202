import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect } from 'react';

import { useSignaturePackage } from "@/hooks";
import { useLazyGetAllSignaturePackagePurchasesByUserIdQuery } from "@/service";

import { colDefs } from "./SignaturePackageList.map";
import { StyledContainer } from './SignaturePackageList.styles';
import SignaturePackageListToolbar from "./SignaturePackageListToolbar";

const SignaturePackageList: FC = () => {
  const apiRef = useGridApiRef();

  const [getAllPurchases, { isLoading }] = useLazyGetAllSignaturePackagePurchasesByUserIdQuery();
  const { signaturePackagePurchasesSt } = useSignaturePackage();

  useEffect(() => {
    getAllPurchases();
  }, []);

  return (
    <StyledContainer data-testid='signature-package-purchases-list-component'>
      <DataGrid
        apiRef={apiRef}
        columns={colDefs}
        rows={signaturePackagePurchasesSt ?? []}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
        loading={isLoading}
        sx={{ borderWidth: 0 }}
        slots={{
          toolbar: SignaturePackageListToolbar,
        }}
        localeText={{
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual",
          filterOperatorStartsWith: "Comienza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Está vacío",
          filterOperatorIsNotEmpty: "No esta vacío",
          filterOperatorIsAnyOf: "Es cualquiera de",
          filterPanelOperator: "Criterio",
          filterPanelColumns: "Columnas",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Ingresa un valor",
          columnMenuSortAsc: "Ordenar ASC",
          columnMenuSortDesc: "Ordenar DESC",
          columnMenuHideColumn: "Ocultar columna",
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
      />
    </StyledContainer>
  );
};

export default SignaturePackageList;