import { Inventory } from '@mui/icons-material';
import { Typography, Box, Grid, Slider, Button, Alert, TextField } from '@mui/material';
import { Formik } from 'formik';
import { FC, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Permissions } from "@/helpers/permissions";
import { useSignaturePackage, useSession, useMessager, useLoader } from '@/hooks';
import AuthorizedRoute from '@/routes/AuthorizedRoute';
import { useLazyGetAllSignaturePackagesQuery, usePurchaseSignaturePackageMutation } from "@/service";
import { ISignaturePackage } from '@/state/SignaturePackage/slice.types';

import { initialValues, validationSchema, SignaturePackagePurchaseValues } from './SignaturePurchaseForm.schema';
import { StyledContainer, StyledPaper } from './SignaturePurchaseForm.styles';
import { SignatureCreditBar } from '../SignatureCreditBar';
import { SocialFooter } from '../SocialFooter';

const SignaturePurchaseForm: FC = () => {
  const navigate = useNavigate();
  const [getAllSignaturePackages, { isLoading, isSuccess }] = useLazyGetAllSignaturePackagesQuery();
  const [purchaseSignaturePackage, { isSuccess: isPostSuccess, isLoading: isPostLoading }] = usePurchaseSignaturePackageMutation();
  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const { sessionUserSt } = useSession();
  const { signaturePackagesSt } = useSignaturePackage();

  useEffect(() => {
    getAllSignaturePackages();
  }, []);

  const handleOnClose = () => {
    navigate('/dashboard/v1/create-document');
  };

  useEffect(() => {
    setLoader(isPostLoading);
    if (isPostSuccess && !isPostLoading) {
      setMessage({
        title: "Compra realizada con éxito",
        body: (
          <Alert severity="success">
            Se ha realizado la compra éxitosamente. Le llegará un correo eléctronico con un enlace para proceder al pago de la compra del paquete de firmas.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      })
    }
  }, [isPostLoading, isPostSuccess])

  const onSubmit = (values: SignaturePackagePurchaseValues) => {
    const { quantity } = values;
    const currentPackage = signaturePackagesSt.find((pack: ISignaturePackage) => (quantity >= pack.min_quantity && quantity <= pack.max_quantity));
    purchaseSignaturePackage({
      quantity,
      package_id: currentPackage.id,
      user_id: sessionUserSt.id,
    });
  };

  const marks = signaturePackagesSt.map((pack: ISignaturePackage) => ({
    label: pack.name,
    value: pack.max_quantity,
  }));

  const getPackagePriceByQuantity = (quantity: number) => {
    const currentPackage = signaturePackagesSt.find((pack: ISignaturePackage) => (quantity >= pack.min_quantity && quantity <= pack.max_quantity));
    if (currentPackage) {
      return currentPackage.price_per_signature;
    }
    return 0;
  }

  return (
    <AuthorizedRoute allowedPermission={Permissions.PURCHASE_USER_SIGNATURE_CREDITS}>
      <StyledContainer data-testid='purchase-credits-form-component'>
        <Typography variant="h4" align="center" color="primary">Comprar Créditos de Firmas</Typography>
        {(sessionUserSt && sessionUserSt.signature_credits) && (
          <SignatureCreditBar />
        )}
        <Box width={{ xs: "100%", md: "70%", lg: "60%" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center">Selecciona un monto:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          name="quantity"
                          value={values.quantity}
                          onChange={handleChange}
                          aria-label="input-slider"
                          valueLabelDisplay="auto"
                          min={10}
                          max={5000}
                          disabled={isLoading}
                          marks={marks}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          variant="outlined"
                          value={values.quantity}
                          size="small"
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue('quantity', event.target.value === '' ? 0 : Number(event.target.value))}
                          onBlur={() => {
                            if (values.quantity < 0) {
                              setFieldValue('quantity', 0);
                            } else if (values.quantity > 5000) {
                              setFieldValue('quantity', 5000);
                            }
                          }}
                          disabled={isLoading}
                          inputProps={{
                            min: 10,
                            max: 5000,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                          }}
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                  {isSuccess && (
                    <Grid item xs={12}>
                      <StyledPaper>
                        <Typography variant="h6" align="center" color="primary">El válor de venta es:</Typography>
                        <Typography variant="body1" align="center">
                          ${(getPackagePriceByQuantity(values.quantity) * values.quantity).toLocaleString('es-ES')} MXN + IVA
                        </Typography>
                      </StyledPaper>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={isLoading || isPostLoading}
                      endIcon={<Inventory />}
                    >
                      Comprar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
        <SocialFooter />
      </StyledContainer>
    </AuthorizedRoute>
  );
};

export default SignaturePurchaseForm;