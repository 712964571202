import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import DocumentsOverview from "@/components/DocumentsOverview";
import {
  Document,
  EDocumentPaymentStatus,
  EDocumentStatus,
} from "@/components/UserDocumentStatus/UserDocumentStatus.types";
import { Permissions } from "@/helpers/permissions";
import { useLoader } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useLazyGetAllDocumentsQuery } from "@/service";

import { IDocumentsStatusDataGridProps } from "./DocumentsStatusDataGrid.types";

const DocumentsStatusDataGrid: React.FC<IDocumentsStatusDataGridProps> = ({
  criteria,
}) => {
  const { setLoader } = useLoader();
  const [filteredData, setFilteredData] = useState<{
    data: { data: { contracts: Document[] } };
  } | null>(null);
  const [getAllDocuments, { data, isLoading, isSuccess }] =
    useLazyGetAllDocumentsQuery();

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      const final = data.data.filter((doc: Document) => {
        switch (criteria) {
          case "payments":
            return (
              doc.status === Object.keys(EDocumentStatus)[0] ||
              doc.status === Object.keys(EDocumentStatus)[2]
            );
          case "signatures":
            return (
              doc.status === Object.keys(EDocumentStatus)[3] &&
              doc.payment_status === Object.keys(EDocumentPaymentStatus)[1]
            );
          case "delivery":
            return (
              doc.status === Object.keys(EDocumentStatus)[1] &&
              doc.payment_status === Object.keys(EDocumentPaymentStatus)[1]
            );
          case "all":
            return doc;
        }
      });

      setFilteredData({ ...data, data: [...final] });
    }
  }, [data, isSuccess, criteria]);

  return (
    <AuthorizedRoute allowedPermission={Permissions.LIST_USER_DOCUMENTS}>
      <Grid
        container
        direction='column'
        gap={2}
        data-testid='documents-status-data-grid-component'
      >
        <Grid item>
          {isSuccess && <DocumentsOverview documents={data.data} />}
        </Grid>
        <Grid item>
          <Outlet context={filteredData} />
        </Grid>
      </Grid>
    </AuthorizedRoute>
  );
};

export default DocumentsStatusDataGrid;
