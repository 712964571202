import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // FormControl,
  // FormControlLabel,
  // FormLabel,
  // RadioGroup,
  // Radio,
  // Stack,
  // styled,
  Typography,
  lighten,
} from "@mui/material";
import { Formik, Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-mui";
import { isEqual } from "lodash";
import React, { useRef } from "react";

import { ReactComponent as CardPenIcon } from "@/assets/icons/card-pen-icon.svg";
import { ReactComponent as PaymentCardIcon } from "@/assets/icons/payment-card-icon.svg";
import { ReactComponent as PenIcon } from "@/assets/icons/pen-icon.svg";
import { useMessager, useSession } from "@/hooks";
import useDocument from "@/hooks/useDocument";
import {
  EDocumentSignerRoles,
  IDocumentSigner,
} from "@/state/Document/slice.types";
import { theme } from "@/theme";

import { validationSchema } from "./SignersModal.schema";
import {
  StyledFormContentContainer,
  StyledFormIconButton,
  StyledFormInputContainer,
  StyledFormRoleInputContainer,
  StyledFormSignerRolesContainer,
} from "./SignersModal.styles";
import { ISignersModalProps } from "./SignersModal.types";

const SignersModal: React.FC<ISignersModalProps> = ({
  participant = 1,
  open,
  signer = null,
  paymentPercentage,
  onClose,
}) => {
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const { setMessage } = useMessager();
  const { sessionUserSt } = useSession();
  const { addNewSigner, updateSignerFromDocument, documentSignersSt } =
    useDocument();
  const initialValues = {
    first_name: "",
    last_name: "",
    mother_last_name: "",
    email: "",
    rfc: "",
    role: "",
    portion: 100,
  };

  const handleRoleToggle = (
    role: EDocumentSignerRoles,
    values: IDocumentSigner,
    paymentPercentage?: number,
  ) => {
    if (paymentPercentage === 100) {
      return { opacity: 0.5 };
    }

    if (values.role === role) {
      return {
        backgroundColor: lighten(theme.palette.atmosphere?.light ?? "", 0.6),
      };
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    onClose && onClose();
  };

  // const StyledRadioButton = styled(Radio)(({ theme }) => ({
  //   color: theme.palette.atmosphere?.main,
  //   "&.Mui-checked": {
  //     color: theme.palette.atmosphere?.main,
  //   },
  // }));
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid='signers-modal-component'
      fullWidth={true}
      maxWidth='md'
    >
      <DialogTitle marginBottom={2}>Participante {participant}</DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={signer ?? initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            const onlySigners = documentSignersSt.filter(
              (sg: IDocumentSigner) => sg.role === EDocumentSignerRoles.SIGNER,
            );
            if (onlySigners.length <= 10) {
              addNewSigner({
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                mother_last_name: values.mother_last_name,
                rfc: values.rfc,
                role: values.role as EDocumentSignerRoles,
                portion: values.portion,
              });
              setSubmitting(false);
            } else {
              setMessage({
                title: "No puede añadir mas firmantes al contrato",
                body: "Ha alcanzado el número máximo de firmantes por contrato, por favor verifique el listado de firmantes.",
                open: true,
                type: "modal",
                buttonCloseTitle: "Entendido",
              });
            }
            onClose && onClose();
          }}
        >
          {({
            setValues,
            submitForm,
            setErrors,
            isSubmitting,
            values,
            // errors,
            initialValues,
          }) => {
            const onSubmit = () => {
              if (!signer) {
                const rfcIsNotUniq = documentSignersSt.some(
                  (sg: { rfc: string }) => sg.rfc === values.rfc,
                );

                const emailIsNotUniq = documentSignersSt.some(
                  (sg: { email: string }) => sg.email === values.email,
                );

                if (values.role === EDocumentSignerRoles.SIGNER) {
                  values.portion = 0;
                }

                if (values.role === EDocumentSignerRoles.PAYER || values.role === EDocumentSignerRoles.SIGNER_PAYER) { 
                  if (values.portion === 0) {
                    return setErrors({
                      portion: "Debe seleccionar un porcentaje a cancelar",
                    });
                  }
                  if (paymentPercentage === 100) {
                    return setErrors({
                      portion:
                        "No es posible añadir este pagador, ya que se ha alcanzado el 100% del total del pago",
                    });
                  }
                }

                if (emailIsNotUniq || rfcIsNotUniq) {
                  return (
                    emailIsNotUniq &&
                      setErrors({ email: "Correo electrónico duplicado" }),
                    rfcIsNotUniq && setErrors({ rfc: "Documento duplicado" })
                  );
                }
                return submitForm();
                
              }

              if (values.role === EDocumentSignerRoles.SIGNER) {
                values.portion = 0;
              }

              const signersDiff = documentSignersSt.filter(
                (sg: { id: number | undefined }) => sg.id !== signer?.id,
              );

              const emailIsNotUniq = signersDiff.some(
                (sg: { email: string }) => sg.email === values.email,
              );

              const rfcIsNotUniq = signersDiff.some(
                (sg: { rfc: string }) => sg.rfc === values.rfc,
              );

              if (rfcIsNotUniq || emailIsNotUniq) {
                return (
                  rfcIsNotUniq && setErrors({ rfc: "Documento duplicado" }),
                  emailIsNotUniq &&
                    setErrors({ email: "Correo electrónico duplicado" })
                );
              }

              return (
                updateSignerFromDocument({
                  prevUuid: signer?.uuid,
                  data: values as IDocumentSigner,
                }),
                onClose && onClose()
              );
            };
            return (
              <StyledFormContentContainer>
                <StyledFormInputContainer>
                  <Field
                    component={TextField}
                    name='first_name'
                    label='Nombre completo'
                    placeholder='Hernán José'
                    type='text'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name='last_name'
                    label='Apellido paterno'
                    placeholder='Álvarez'
                    type='text'
                    fullWidth
                  />
                </StyledFormInputContainer>
                <StyledFormInputContainer>
                  <Field
                    component={TextField}
                    name='mother_last_name'
                    label='Apellido materno'
                    placeholder='Gónzalez'
                    type='text'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name='email'
                    label='Correo electrónico'
                    placeholder='tucorreo@mail.com'
                    type='text'
                    fullWidth
                  />
                </StyledFormInputContainer>
                <StyledFormInputContainer>
                  <Field
                    component={TextField}
                    name='rfc'
                    label='RFC'
                    placeholder='ABCD171284XYZ'
                    type='text'
                    fullWidth
                  />
                  <StyledFormSignerRolesContainer>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      align='center'
                    >
                      ¿Qué rol desempeñará el participante?
                    </Typography>
                    <StyledFormRoleInputContainer>
                      <Box
                        display='flex'
                        flexDirection='column'
                        gap={1}
                        flex={1}
                        alignItems='center'
                      >
                        <StyledFormIconButton
                          size='medium'
                          name={`${EDocumentSignerRoles.PAYER}`}
                          sx={handleRoleToggle(
                            EDocumentSignerRoles.PAYER,
                            values as IDocumentSigner,
                          )}
                          onClick={() =>
                            setValues({
                              ...values,
                              role: EDocumentSignerRoles.PAYER,
                            })
                          }
                          disabled={sessionUserSt.signature_credits && sessionUserSt.signature_credits.remaining_quantity > 0}
                        >
                          <PaymentCardIcon width={22} />
                        </StyledFormIconButton>
                        <Typography
                          variant='body2'
                          align='center'
                          fontSize={theme.spacing(1.5)}
                        >
                          Pagador
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        gap={1}
                        flex={1}
                        alignItems='center'
                      >
                        <StyledFormIconButton
                          size='medium'
                          name={`${EDocumentSignerRoles.SIGNER}`}
                          sx={handleRoleToggle(
                            EDocumentSignerRoles.SIGNER,
                            values as IDocumentSigner,
                          )}
                          onClick={() =>
                            setValues({
                              ...values,
                              role: EDocumentSignerRoles.SIGNER,
                            })
                          }
                        >
                          <PenIcon width={22} />
                        </StyledFormIconButton>
                        <Typography
                          variant='body2'
                          align='center'
                          fontSize={theme.spacing(1.5)}
                        >
                          Firmante
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        gap={1}
                        flex={1}
                        alignItems='center'
                      >
                        <StyledFormIconButton
                          size='medium'
                          sx={handleRoleToggle(
                            EDocumentSignerRoles.SIGNER_PAYER,
                            values as IDocumentSigner,
                          )}
                          name={`${EDocumentSignerRoles.SIGNER_PAYER}`}
                          onClick={() =>
                            setValues({
                              ...values,
                              role: EDocumentSignerRoles.SIGNER_PAYER,
                            })
                          }
                          disabled={sessionUserSt.signature_credits && sessionUserSt.signature_credits.remaining_quantity > 0}
                        >
                          <CardPenIcon width={35} />
                        </StyledFormIconButton>
                        <Typography
                          variant='body2'
                          align='center'
                          fontSize={theme.spacing(1.5)}
                        >
                          Pagador y firmante
                        </Typography>
                      </Box>
                    </StyledFormRoleInputContainer>
                    {/* {values.role === EDocumentSignerRoles.SIGNER_PAYER ||
                    values.role === EDocumentSignerRoles.PAYER ? (
                      <Box margin='0 auto'>
                        <FormControl>
                          <FormLabel id='portion-buttons-group-label'>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='black'
                              align='center'
                            >
                              Selecciona porcentaje a pagar
                            </Typography>
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby='portion-buttons-group-label'
                            name='portion'
                            defaultValue={signer ? signer.portion : null}
                            onChange={el => {
                              setValues({
                                ...values,
                                portion: Number(el.target.value),
                              });
                            }}
                          >
                            <Stack
                              direction='row'
                              justifyContent='center'
                              spacing={2}
                            >
                              <FormControlLabel
                                value={50}
                                control={<StyledRadioButton />}
                                label='50%'
                              />
                              <FormControlLabel
                                value={100}
                                control={<StyledRadioButton />}
                                label='100%'
                              />
                            </Stack>
                          </RadioGroup>
                          {errors?.portion ? (
                            <Typography
                              variant='body2'
                              color='secondary'
                              align='center'
                            >
                              {errors.portion as string}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </FormControl>
                      </Box>
                    ) : (
                      <></>
                    )} */}
                  </StyledFormSignerRolesContainer>
                </StyledFormInputContainer>
                <Button
                  onClick={onSubmit}
                  size='large'
                  variant='contained'
                  disabled={
                    isSubmitting ||
                    isEqual(initialValues, values) ||
                    paymentPercentage > 100
                  }
                  sx={{
                    width: "50%",
                    margin: "0 auto",
                  }}
                >
                  <Typography
                    variant='body1'
                    align='center'
                    fontWeight='bold'
                    textTransform='capitalize'
                  >
                    Agregar participante
                  </Typography>
                </Button>
              </StyledFormContentContainer>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SignersModal;
