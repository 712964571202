import { useDispatch, useSelector } from "react-redux";

import { navigationSelector } from "@/state/Navigation/selectors";
import {
  setShouldResetPassword as setShouldResetPasswordAction,
  setNavigationHistory as setNavigationHistoryAction,

} from "@/state/Navigation/slice";

const useNavigation = () => {
  const dispatch = useDispatch();
  const navigationSt = useSelector(navigationSelector);

  const setShouldResetPassword = (reset: boolean) =>
    dispatch(setShouldResetPasswordAction(reset));

  const setNavigationHistory = (activeSession: boolean) =>
    dispatch(setNavigationHistoryAction({ activeSession }));

  return {
    navigationSt,
    setShouldResetPassword,
    setNavigationHistory
  };
};

export default useNavigation;
