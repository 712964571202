import { Box, styled, BoxProps, SxProps, Theme } from "@mui/material";

export const StyledFooterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(4),
}));

export const iconButtonSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  mx: theme.spacing(1),
  color: theme.palette.common.white,
  '&:is(:hover, :active)': {
    backgroundColor: theme.palette.primary.main,
  }
})