import { Email, Phone, Badge } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";

import { useSession } from "@/hooks";
import { theme } from "@/theme";

import { StyledContainer } from "./UserSettings.styles";

const UserSettings: React.FC = () => {
  const { sessionUserSt } = useSession();
  return (
    <StyledContainer
      item
      container
      data-testid='user-settings-component'
      width='100%'
    >
      <Stack direction='column' spacing={3} justifyContent='center'>
        <Stack direction='column' spacing={0.5}>
          <Typography
            variant='h5'
            mb={theme.spacing(1)}
            color='black'
          >{`${sessionUserSt.first_name} ${sessionUserSt.last_name}`}</Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Badge sx={{ fontSize: theme.typography.body2 }} color='primary' />
            <Typography variant='body2' color='GrayText'>
              {sessionUserSt.rfc}
            </Typography>
          </Stack>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Email sx={{ fontSize: theme.typography.body2 }} color='primary' />
            <Typography variant='body2' color='GrayText'>
              {sessionUserSt.email}
            </Typography>
          </Stack>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Phone sx={{ fontSize: theme.typography.body2 }} color='primary' />
            <Typography variant='body2' color='GrayText'>
              {sessionUserSt.phone}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </StyledContainer>
  );
};

export default UserSettings;
