import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelector, tokenSelector, permissionsSelector, roleSelector } from "@/state/Session/selectors";
import { endSession as endSessionAction } from "@/store";

const useSession = () => {
  const dispatch = useDispatch();
  const sessionUserSt = useSelector(userSelector);
  const sessionPermissionsSt = useSelector(permissionsSelector);
  const sessionTokenSt = useSelector(tokenSelector);
  const sessionRoleSt = useSelector(roleSelector);

  const canUse = useCallback(
    (allowedRole: string) => {
      if (allowedRole && allowedRole.length > 0) {
        return sessionPermissionsSt.includes(allowedRole);
      }
      return true;
    },
    [sessionPermissionsSt]
  );

  const endSession = () => dispatch(endSessionAction());

  return {
    sessionUserSt,
    sessionTokenSt,
    sessionPermissionsSt,
    sessionRoleSt,
    endSession,
    canUse
  };
};

export default useSession;
