import * as yup from "yup";

export interface SignaturePackagePurchaseValues {
  quantity: number
}

export const validationSchema = yup.object({
  quantity: yup.number().min(10).required(),
});


export const initialValues: SignaturePackagePurchaseValues = {
  quantity: 0
}