import jwt from "jwt-decode";
import { DateTime } from "luxon";

import { setMessage } from "@/state/Messager/slice";
import { setNavigationHistory } from "@/state/Navigation/slice";
import { ISessionState } from "@/state/Session/slice.types";
import { store, endSession } from "@/store";

interface DecodedToken {
  user: ISessionState;
  iat: number;
  exp: number;
}

export const tokenIsValid = (token: string) => {
  const decoded = jwt(token) as DecodedToken;
  const currTime = Math.floor(DateTime.now().toSeconds());
  return decoded.exp > currTime;
};

export const resolveSessionQuery = async (callback: Promise<unknown>) => {
  const token = store.getState().session.token;
  const isValid = token ? tokenIsValid(token) : false;

  const displayErrorMessage = () => {
    store.dispatch(
      setMessage({
        title: "Su Sesión ha finalizado",
        body: "La sesión ha finalizado, por favor ingrese sus credenciales nuevamente e inténtelo de nuevo.",
        open: true,
        buttonCloseTitle: "OK",
        type: "modal",
      }),
    );
    store.dispatch(setNavigationHistory({ activeSession: false }));
    store.dispatch(endSession());
  };

  if (isValid) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await callback;

    if (result?.error) {
      store.dispatch(
        setMessage({
          title: "Ha ocurrido un error",
          body: "Lamentamos los inconvenientes, actualmente nuestros servicios están experimentando errores. Por favor inténtalo en unos minutos más.",
          open: true,
          buttonCloseTitle: "Entendido",
          type: "modal",
        }),
      );

      return;
    }

    return result;
  }

  return displayErrorMessage();
};
