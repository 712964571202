import * as yup from "yup";

const rfcRegex = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/i;

export const validationSchema = yup.object({
  first_name: yup.string().required("Introduce tu nombre").min(2).max(256).trim(),
  last_name: yup
    .string()
    .required("Introduce tu apellido paterno")
    .min(2)
    .max(256)
    .trim(),
  mother_last_name: yup
    .string()
    .required("Introduce tu apellido materno")
    .min(2)
    .max(256)
    .trim(),
  role: yup.string().required("Selecciona un Rol"),
  email: yup
    .string()
    .email()
    .required("Introduce tu correo electrónico")
    .trim(),
  rfc: yup
    .string()
    .matches(rfcRegex, "RFC inválido")
    .required("Este campor es requerido"),
  portion: yup
    .number()
    .oneOf([0, 50, 100])
    .required("Selecciona un porcentaje a pagar"),
});
