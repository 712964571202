import { Box, styled, lighten, BoxProps, SxProps, Theme } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  // width: "100%",
  // height: '100%',
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
}));


export const StyledPaper = styled(Box)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.atmosphere?.main ?? "", 0.9),
  borderWidth: 1,
  borderStyle: "dotted",
  borderColor: theme.palette.atmosphere?.main,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderRadius: '10px',
}));


export const StyledFooterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(4),
}));

export const iconButtonSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  mx: theme.spacing(1),
  color: "#ffffff",
  '&:is(:hover, :active)': {
    backgroundColor: theme.palette.primary.main,
  }
})