import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISessionState } from "./slice.types";

const initialState: ISessionState = {
  user: undefined,
  permissions: [],
  password_reset: false,
  token: null,
  role: undefined,
  error: false,
};

export const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    setUserSession: (
      state: ISessionState,
      { payload }: PayloadAction<ISessionState>,
    ) => {
      state.user = payload.user;
      state.permissions = payload.permissions;
      state.role = payload.role;
      state.password_reset = payload.password_reset;
      state.token = payload.token;
    },
    setError: (
      state: ISessionState,
      { payload }: PayloadAction<ISessionState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setUserSession, setError } = sessionSlice.actions;
