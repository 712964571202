import { CreditCard } from "@mui/icons-material";
import {
  Chip,
  Typography,
  Button,
  lighten,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

import { theme } from "@/theme";


export const colDefs: GridColDef[] = [
  {
    field: "quantity",
    headerName: "Cantidad de Firmas",
    width: 200,
    renderCell: ({ value }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: "amount",
    headerName: "Monto Total",
    width: 200,
    renderCell: ({ value }) => <Typography variant="body1">${value.toLocaleString('es-ES')} MXN</Typography>,
  },
  {
    field: "is_paid",
    headerName: "Estado",
    width: 200,
    renderCell: ({ value }) => (
      <Chip
        label={value ? 'PAGADO' : 'NO PAGADO'}
        sx={{ background: value ? lighten(theme.palette.success?.light ?? "", 0.5) : lighten(theme.palette.autumn?.light ?? "", 0.5) }}
      />),
  },
  {
    field: "completed_at",
    headerName: "Fecha de Pago",
    width: 200,
    renderCell: ({ value }) => (<Typography variant="body1">{value ? format(value, 'dd/MM/yyyy') : ''}</Typography>),
  },
  {
    field: "payment_data",
    headerName: "Acciones",
    width: 200,
    renderCell: ({ value, row }) => (!row.is_paid) && (
      <Button
        variant="contained"
        startIcon={<CreditCard />}
        onClick={() => window.open(value.payment_link, "_blank", "noopener,noreferrer")}
      >
        Pagar
      </Button>
    ),
  },
];