import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISignaturePackageState, ISignaturePackage, ISignaturePackagePurchase } from "./slice.types";

const initialState: ISignaturePackageState = {
  signature_packages: [],
  purchases: [],
  error: false,
};

export const signaturePackageSlice = createSlice({
  initialState,
  name: "signature_packages",
  reducers: {
    setSignaturePackages: (
      state: ISignaturePackageState,
      { payload }: PayloadAction<ISignaturePackage[]>,
    ) => {
      state.signature_packages = payload;
    },
    setSignaturePackagePurchases: (
      state: ISignaturePackageState,
      { payload }: PayloadAction<ISignaturePackagePurchase[]>,
    ) => {
      state.purchases = payload;
    },
    setError: (
      state: ISignaturePackageState,
      { payload }: PayloadAction<ISignaturePackageState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setSignaturePackages, setSignaturePackagePurchases, setError } = signaturePackageSlice.actions;
