import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useSession } from '@/hooks';


interface AuthorizedRouteProps {
  children: ReactNode;
  allowedPermission: string;
}

const AuthorizedRoute: FC<AuthorizedRouteProps> = ({ children, allowedPermission }) => {
  const { canUse } = useSession();

  let canAccess = false;

  if (allowedPermission) {
    canAccess = canUse(allowedPermission);
  }

  return <>{canAccess ? children : <Navigate to="/dashboard/v1" replace />}</>;
};

export default AuthorizedRoute;