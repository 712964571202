import { Delete, Edit } from "@mui/icons-material";
import {
  Divider,
  ListItemText,
  IconButton,
  ListItemIcon,
  Stack,
  Typography,
  Button,
  Box,
} from "@mui/material";
import React, { useState } from "react";

import useDocument from "@/hooks/useDocument";
import { theme } from "@/theme";

import {
  StyledCircle,
  StyledAddBox,
  StyledRemoveItem,
} from "./SignersListItem.styles";
import { ISignersListItemProps } from "./SignersListItem.types";

const SignersListItem: React.FC<ISignersListItemProps> = ({
  item,
  editable,
  primaryText,
  onEdit,
  onExpandClick,
  onDeleteClick,
}) => {
  const [remove, setRemove] = useState<boolean>(false);
  const { removeSignerFromDocument } = useDocument();

  const handleSignerUpdate = (e: unknown) => {
    onEdit && onEdit(e);
    onExpandClick && onExpandClick(e);
  };

  const handleSignerDeletion = (e: unknown) => {
    onEdit && onEdit(e);
    setRemove(prev => !prev);
    onDeleteClick && onDeleteClick(e);
  };

  const deleteSigner = (uuid: string) => {
    removeSignerFromDocument(uuid);
  };

  return (
    <>
      <StyledRemoveItem remove={remove} alignItems='flex-start'>
        <ListItemIcon sx={{ minWidth: 33 }}>
          <StyledCircle
            sx={{
              color: remove ? theme.palette.common.black : "",
              width: theme.spacing(2),
              height: theme.spacing(2),
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={primaryText}
          placeholder='Agregar participante'
          primaryTypographyProps={{
            color: theme.palette.common.black,
            fontWeight: remove ? "bold" : "regular",
          }}
        />
        {editable ? (
          <Stack direction='row' flexWrap='wrap' useFlexGap>
            {!remove ? (
              <IconButton onClick={handleSignerDeletion}>
                <Delete
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                />
              </IconButton>
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={2}
              >
                <Typography variant='body2' fontWeight='bold'>
                  ¿Eliminar participante?
                </Typography>
                <Button
                  onClick={() => deleteSigner(item)}
                  variant='contained'
                  color='secondary'
                  sx={{ height: theme.spacing(3) }}
                >
                  <Typography variant='body2' fontWeight='bold'>
                    SI
                  </Typography>
                </Button>
                <Button
                  onClick={handleSignerDeletion}
                  variant='contained'
                  color='almostwhite'
                  sx={{ height: theme.spacing(3) }}
                >
                  <Typography variant='body2' fontWeight='bold'>
                    NO
                  </Typography>
                </Button>
              </Box>
            )}
            {!remove && (
              <IconButton onClick={handleSignerUpdate} disabled={remove}>
                <Edit sx={{ color: theme.palette.primary.light }} />
              </IconButton>
            )}
          </Stack>
        ) : (
          <IconButton onClick={e => onExpandClick && onExpandClick(e)}>
            <StyledAddBox />
          </IconButton>
        )}
      </StyledRemoveItem>
      <Divider variant='fullWidth' component='li' />
    </>
  );
};

export default SignersListItem;
