import { faker } from "@faker-js/faker";

import { ISessionsCreateUserProps } from "@/service/service.types";

export const fakeUser = (): ISessionsCreateUserProps => ({
  email: faker.internet.email({
    firstName: "fv",
    lastName: "fsession",
  }) as string,
  first_name: faker.lorem.word({ length: 2 }) as string,
  last_name: faker.lorem.word({ length: 1 }) as string,
  mother_last_name: faker.lorem.word({ length: 1 }) as string,
  password: faker.internet.password() as string,
  phone: faker.string.numeric({ length: 9 }) as string,
  rfc: `FHF99${faker.string.numeric({ length: 4 })}33`,
  username: faker.internet.userName() as string,
});
