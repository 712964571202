import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import {
  Formik,
  Field,
  FieldInputProps,
  FieldMetaProps,
  FormikProps,
} from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useMessager } from "@/hooks";
import {
  useLoginUserMutation,
} from "@/service";
import { theme } from "@/theme";

import { validationSchema } from "./LoginForm.schema";
import {
  StyledContainer,
  StyledFormInputsContainer,
  StyledInputContainer,
} from "./LoginForm.styles";

const LoginForm: React.FC = () => {
  const { setMessage } = useMessager();
  const [showPassword, setShowPassword] = useState<boolean>();
  const [loginUser, { isSuccess: loginSuccess, data, isLoading: loginLoading }] = useLoginUserMutation();
  const elementRef = useRef<React.ReactElement>(null);
  const formikRef =
    useRef<FormikProps<{ email: string; password: string }>>(null);
  const navigation = useNavigate();

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const checkVerifyPassword = (passwordReset: boolean) => {
    if (passwordReset) {
      navigation("/session/update-password");
    } else {
      navigation("/dashboard/v1/create-document");
    }
  };

  useEffect(() => {
    if (loginSuccess) {
      if (data.data.error) {
        setMessage({
          title: "Usuario o contraseña incorrectos",
          body: "El usuario o la contraseña proporcionados son incorrectos, por favor verifique sus credenciales.",
          open: true,
          buttonCloseTitle: "Volver a intentarlo",
          type: "modal",
        });
      } else {
        checkVerifyPassword(data.data.password_reset);
      }
    } 
  }, [loginSuccess]);

  return (
    <StyledContainer ref={elementRef}>
      <Formik
        data-testid='login-form-component'
        innerRef={formikRef}
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          loginUser({ email: values.email, password: values.password });
        }}
      >
        {({ submitForm }) => (
          <StyledFormInputsContainer>
            <Field
              component={TextField}
              name='email'
              type='text'
              label='Correo electrónico'
              fullWidth
            />
            <StyledInputContainer>
              <Field name='password'>
                {({
                  field,
                  meta,
                }: {
                  field: FieldInputProps<unknown>;
                  meta: FieldMetaProps<unknown>;
                }) => (
                  <FormControl sx={{ width: "100%" }} variant='outlined'>
                    <InputLabel htmlFor='outlined-adornment-password'>
                      Contraseña
                    </InputLabel>
                    <OutlinedInput
                      {...field}
                      id='outlined-adornment-password'
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {meta.touched && meta.error ? (
                      <Typography variant='body2' color='tomato'>
                        {meta.error}
                      </Typography>
                    ) : null}
                  </FormControl>
                )}
              </Field>
              <Link to='/session/reset-password'>
                <Typography variant='body2' fontWeight='bold' color='GrayText'>
                  ¿Has olvidado tu contraseña?
                </Typography>
              </Link>
            </StyledInputContainer>
            <Stack spacing={4}>
              <Button
                variant='contained'
                fullWidth
                size='large'
                disabled={loginLoading}
                onClick={submitForm}
                sx={{ mt: theme.spacing(2) }}
              >
                <Typography
                  variant='body1'
                  align='center'
                  fontWeight='bold'
                  textTransform='capitalize'
                >
                  Continuar
                </Typography>
              </Button>
              <Typography variant='body2' align='center'>
                ¿Nuevo en nuestra plataforma?{" "}
                <Link to='/session/signup'>
                  <Typography variant='body2' fontWeight='bold' color='primary'>
                    Crea una cuenta
                  </Typography>
                </Link>
              </Typography>
            </Stack>
          </StyledFormInputsContainer>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default LoginForm;
