import { useDispatch, useSelector } from "react-redux";

import { signaturePackagesPurchasesSelector, signaturePackagesSelector } from "@/state/SignaturePackage/selectors";
import {
  setSignaturePackages as setSignaturePackagesAction,
  setSignaturePackagePurchases as setSignaturePackagePurchasesAction
} from "@/state/SignaturePackage/slice";
import { ISignaturePackage, ISignaturePackagePurchase } from "@/state/SignaturePackage/slice.types";


const useSession = () => {
  const dispatch = useDispatch();
  const signaturePackagesSt = useSelector(signaturePackagesSelector);
  const signaturePackagePurchasesSt = useSelector(signaturePackagesPurchasesSelector);

  const setSignaturePackages = (signaturePackages: ISignaturePackage[]) => dispatch(setSignaturePackagesAction(signaturePackages));
  const setSignaturePackagePurchases = (purchases: ISignaturePackagePurchase[]) => dispatch(setSignaturePackagePurchasesAction(purchases));

  return {
    signaturePackagesSt,
    signaturePackagePurchasesSt,
    setSignaturePackages,
    setSignaturePackagePurchases,
  };
};

export default useSession;
