import { Document } from "@/components/UserDocumentStatus/UserDocumentStatus.types";
import { IServiceErrorSatus } from "@/service/service.types";

export interface ISliceDocumentState {
  documentCreationStep: EDocumentCreationSteps | null;
  documentType: EDocumentTypes | null;
  documentOrderInfo: IDocumentOrderInfo | null;
  documentSigners: Array<IDocumentSigner>;
  documentUrl: string | null;
  documentIsDone: boolean;
  documentSigningPage: number | null;
  documents?: Document[] | null;
  documentBase64?: string | null;
  error?: IServiceErrorSatus["error"];

  contractTypes: IContractType[];
}

export interface IContractType {
  id: number;
  contractType: IContractTypeEnum;
  contractTypeName: string;
}

export enum IContractTypeEnum {
  REAL_ESTATE,
  LABOR,
  PERSONAL,
  POWER,
  COMPANY,
  SOCIETY,
  VEHICLE
}

export interface IDocumentOrderInfo {
  selectedDate: Date;
  documentDuration: string;
  dealAmount: string;
  address: string;
  postalCode: string;
}

export interface IDocumentSigner {
  first_name: string;
  last_name: string;
  mother_last_name: string;
  email: string;
  rfc: string;
  role: EDocumentSignerRoles;
  portion: number;
  id?: number;
  uuid?: string;
  posX?: number;
  posY?: number;
}

export enum EDocumentTypes {
  RENTING = 1,
  BUYING_PROMISE = 2,
  NONE = 0,
}

export enum EDocumentSignerRoles {
  SIGNER = 'SIGNER',
  PAYER = 'PAYER',
  SIGNER_PAYER = 'SIGNER_PAYER',
}

export enum EDocumentCreationSteps {
  SET_TYPE = "set-type",
  SET_SIGNERS = "set-signers",
  SET_NOTIFICATION = "set-notification",
}
