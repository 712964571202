import {
  Box,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Stack,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DisplaySignersList from "@/components/DisplaySignersList";
import PDFSignersPage from "@/components/PDFSignersPage";
// import { signersPosition } from "@/components/PDFSignersPage/PDFSignersPage.map";
import SignersModal from "@/components/SignersModal";
import { Permissions } from "@/helpers/permissions";
import { useSession, useDocument } from "@/hooks";
import { useCreateDocumentMutation } from "@/service";
import {
  EDocumentCreationSteps,
  EDocumentSignerRoles,
  IDocumentSigner,
} from "@/state/Document/slice.types";
import { theme } from "@/theme";

import {
  StyledContainer,
  StyledSignersContainer,
  StyledSignersListContainer,
  StyledSignersTitleContainer,
} from "./Signers.styles";
import { ISignersProps } from "./Signers.types";

const Signers: React.FC<ISignersProps> = ({
  onClickPrev,
  onClickNext,
  createPDFDocument,
}) => {
  const navigation = useNavigate();
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const [participantSelected, setParticipantSelected] = useState<number>(-1);
  const [payerSelected, setPayerSelected] = useState<boolean>(false);
  const [signersSelected, setSignersSelected] = useState<boolean>(false);
  const [paymentPercentage, setPaymentPercentage] = useState<number>(0);
  const [hasRemainingCredits, setHasRemainingCredits] = useState<boolean>(false);

  const {
    documentSt,
    documentSignersSt,
    documentBase64St,
    setDocumentStep,
    documentWithSignersPage,
  } = useDocument();
  const { sessionUserSt } = useSession();
  const [createDocument, { isLoading, isSuccess }] =
    useCreateDocumentMutation();
  const { canUse } = useSession();
  
  const spring = useSpring({
    from: { opacity: 0, display: "none", transform: "translate3d(100%,0,0)" },
    to: { opacity: 1, display: "flex", transform: "translate3d(0,0,0)" },
  });
  const currentParticipant = documentSignersSt
    ? documentSignersSt.length + 1
    : 1;
  

  const AnimatedContainer = animated(StyledContainer);

  useEffect(() => {
    setPaymentPercentage(
      documentSignersSt.reduce((sum, item) => sum + item.portion, 0),
    );
  }, [documentSignersSt]);

  useEffect(() => {
    const hasPayers = documentSignersSt.some(
      (sg: IDocumentSigner) =>
        sg.role === EDocumentSignerRoles.PAYER ||
        sg.role === EDocumentSignerRoles.SIGNER_PAYER,
    );

    const hasSigners = documentSignersSt.some(
      (sg: IDocumentSigner) =>
        sg.role === EDocumentSignerRoles.SIGNER ||
        sg.role === EDocumentSignerRoles.SIGNER_PAYER,
    );

    setPayerSelected(hasPayers);
    setSignersSelected(hasSigners);
  }, [documentSignersSt]);

  useEffect(() => {
    if (sessionUserSt.signature_credits !== null && sessionUserSt.signature_credits?.remaining_quantity > 0) {
      setHasRemainingCredits(true);
    }
  }, [hasRemainingCredits, sessionUserSt])

  useEffect(() => {
    isSuccess && navigation("/dashboard/v1/document-is-ready");
  }, [isSuccess]);

  const handleModalDisplay = () => {
    setParticipantSelected(-1);
    setOpenModalForm(prev => !prev);
  };

  console.log('Has remaining credits', hasRemainingCredits)
  console.log('Credits', sessionUserSt.signature_credits)

  const handlePrev = () => {
    setDocumentStep(EDocumentCreationSteps.SET_TYPE);
    navigation("/dashboard/v1/create-document");
    onClickPrev && onClickPrev();
  };

  const handleNext = () => {
    createPDFDocument && setDocumentBase64();
    onClickNext && onClickNext();
    setDocumentStep(EDocumentCreationSteps.SET_NOTIFICATION);
  };

  const setDocumentBase64 = async () => {
    if (documentBase64St) {
      const listSigners = documentSignersSt.filter(
        (sg: IDocumentSigner) =>
          sg.role === EDocumentSignerRoles.SIGNER_PAYER ||
          sg.role === EDocumentSignerRoles.SIGNER,
      );

      const result = await documentWithSignersPage({
        base64: documentBase64St,
        signersComponentPage: <PDFSignersPage signers={listSigners} />,
      });

      const resultData = result?.split("data:application/pdf;base64,")[1];

      if (result) {
        createDocument({
          owner_id: sessionUserSt.id,
          owner_type: "NATURAL",
          // payment_service: 1,
          document_type_fee_id: documentSt.documentType,
          file: resultData,
          signer_count: listSigners.length,
          page_sign: documentSt.documentSigningPage,
          signers: documentSignersSt.map(
            (sg: IDocumentSigner) => ({
              rfc: sg.rfc,
              first_name: sg.first_name,
              last_name: sg.last_name,
              mother_last_name: sg.mother_last_name,
              email: sg.email,
              role: sg.role,
              signer_type: "NATURAL",
              portion: 100,
              // posX: signersPosition[index].PosX,
              // posY: signersPosition[index].PosY,
            }),
          ),
        });
      }
    }
  };

  return (
    <>
      <SignersModal
        open={openModalForm}
        participant={currentParticipant}
        onClose={handleModalDisplay}
        paymentPercentage={paymentPercentage}
        signer={documentSignersSt[participantSelected]}
      />
      <AnimatedContainer style={spring} data-testid='signers-component'>
        {!isLoading ? (
          <>
            <Typography variant='h5' align='center' color='primary'>
              Formulario para generar documentos
            </Typography>
            <StyledSignersContainer>
              <StyledSignersTitleContainer sx={{ display: 'none' }}>
                <Typography variant='body1' align='left' fontWeight='bold'>
                  Tipo de Contrato
                </Typography>
              </StyledSignersTitleContainer>
              <StyledSignersTitleContainer>
                <Typography variant='body1' align='left' fontWeight='bold'>
                  Participantes
                </Typography>
              </StyledSignersTitleContainer>
              <StyledSignersListContainer>
                <DisplaySignersList
                  setSignerCallback={setParticipantSelected}
                  setEditSignerCallback={setOpenModalForm}
                />
                {(!payerSelected && !sessionUserSt.signature_credits && !hasRemainingCredits) && (
                  <Alert severity='warning' sx={{ borderRadius: 0 }}>
                    Debes elegir al menos un (1) pagador
                  </Alert>
                )}
                {!signersSelected && (
                  <Alert severity='warning' sx={{ borderRadius: 0 }}>
                    Debes elegir al menos un (1) firmante
                  </Alert>
                )}
                {/* {paymentPercentage !== 100 && (
                  <Alert severity='warning' sx={{ borderRadius: 0 }}>
                    Aún no has cubierto el 100% del pago
                  </Alert>
                )} */}
              </StyledSignersListContainer>
            </StyledSignersContainer>
            <Stack direction='row' spacing={2}>
              <Button
                variant='contained'
                onClick={handlePrev}
                size='large'
                sx={{
                  width: theme.spacing(20),
                }}
              >
                <Typography
                  variant='body1'
                  align='center'
                  fontWeight='bold'
                  textTransform='capitalize'
                >
                  Volver
                </Typography>
              </Button>
              {canUse(Permissions.CREATE_USER_DOCUMENT) && (
                <Button
                  variant='contained'
                  onClick={handleNext}
                  disabled={
                    !Boolean(documentSignersSt.length) ||
                    (!payerSelected && !sessionUserSt.signature_credits) ||
                    !signersSelected ||
                    // paymentPercentage !== 100 ||
                    isLoading
                  }
                  size='large'
                  sx={{
                    width: theme.spacing(20),
                  }}
                >
                  <Typography
                    variant='body1'
                    align='center'
                    fontWeight='bold'
                    textTransform='capitalize'
                  >
                    Iniciar proceso
                  </Typography>
                </Button>
              )}
            </Stack>
          </>
        ) : (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100%'
          >
            <CircularProgress color='primary' />
          </Box>
        )}
      </AnimatedContainer>
    </>
  );
};

export default Signers;