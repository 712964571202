import { Person } from "@mui/icons-material";
import { Typography, Button, Chip } from "@mui/material";
import React from "react";

import { useSession } from "@/hooks";
import { theme } from "@/theme";

import {
  StyledContainer,
  StyledInfoContainer,
  StyledPictureContainer,
} from "./AvatarContainer.styles";
import { IAvatarContainerProps } from "./AvatarContainer.types";

const AvatarContainer: React.FC<IAvatarContainerProps> = ({
  userName,
  userRole,
  userPicture,
}) => {
  const { sessionUserSt } = useSession();

  return (
    <StyledContainer data-testid='avatar-container-component'>
      {sessionUserSt && sessionUserSt.signature_credits && (
        <Button
          variant="contained"
          href="#"
        >
          Mís Créditos
          <Chip
            label={sessionUserSt.signature_credits?.remaining_quantity}
            size="small"
            sx={{ ml: 1, backgroundColor: '#ffffff' }}
          />
        </Button>
      )}
      <StyledInfoContainer>
        <Typography variant='h6' lineHeight={1} fontSize={14}>
          {userName}
        </Typography>
        <Typography
          variant='body2'
          fontSize={14}
          color={theme.palette.grey[500]}
        >
          {userRole}
        </Typography>
      </StyledInfoContainer>
      <StyledPictureContainer>
        {userPicture ?? <Person color='primary' />}
      </StyledPictureContainer>
    </StyledContainer>
  );
};

export default AvatarContainer;
