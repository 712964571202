import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Typography, Box, IconButton } from '@mui/material';
import { FC } from 'react';

import { StyledFooterContainer, iconButtonSx } from './SocialFooter.styles';

const SocialFooter: FC = () => {
  return (
    <StyledFooterContainer>
      <Typography variant="body1" fontWeight="bold" color="primary">
        Gracias por usar nuestros servicios
      </Typography>
      <Typography variant="caption" fontWeight="bold">
        Para más información, comunícate con nuestro equipo de soporte al cliente:
      </Typography>
      <Box display="flex" justifyContent="space-evenly">
        <IconButton
          aria-label="whatsapp"
          size="large"
          sx={iconButtonSx}
          onClick={() => window.open(
            "https://api.whatsapp.com/send?phone=5215639550122",
            "_blank",
            "noopener,noreferrer"
          )}
        >
          <WhatsAppIcon />
        </IconButton>
        <IconButton
          aria-label="whatsapp"
          size="large"
          sx={iconButtonSx}
          onClick={() => (window.location.href = "tel:+5215639550122")}
        >
          <PhoneIcon />
        </IconButton>
        <IconButton
          aria-label="whatsapp"
          size="large"
          sx={iconButtonSx}
          onClick={() => (window.location.href = "mailto:diego@firmavirtual.legal")}
        >
          <MailOutlinedIcon />
        </IconButton>
      </Box>
    </StyledFooterContainer>
  );
};

export default SocialFooter;