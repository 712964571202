import { Box, BoxProps, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flex: 1,
  gap: theme.spacing(2),
  height: "100%",
}));

export const StyledContentContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(7),
}));

export const StyledOptionsContainer = styled(Box)<BoxProps>(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "flex-start",
}));

export const StyledButtonContainer = styled(Box)<BoxProps>(() => ({
  minWidth: "30%",
  maxWidth: "45%",
}));

export const StyledButton = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

export const StyledFooterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.5),
  "& div.icons": {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-evenly",
    "& div:nth-child(1), div:nth-child(2)": {
      marginRight: 15,
    },
    "& div.icon-contact": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      width: "40px",
      height: "40px",
      borderRadius: "19px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));