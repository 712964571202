import { CloudUpload } from "@mui/icons-material";
import DrawIcon from "@mui/icons-material/Draw";
import { Box, Typography, Button, Grid } from "@mui/material";
import React from "react";
import { Outlet, NavLink } from "react-router-dom";

import { ReactComponent as PaperArrowIcon } from "@/assets/icons/paper-arrow-icon.svg";
import { ReactComponent as ParticipantsIcon } from "@/assets/icons/participants-icon.svg";
import DocumentOption from "@/components/DocumentOption";
import FileLoadInput from "@/components/FileLoadInput";
import { Permissions } from "@/helpers/permissions";
import { useSession, useDocument } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { EDocumentTypes } from "@/state/Document/slice.types";

import {
  StyledButtonContainer,
  StyledContainer,
  StyledContentContainer,
  StyledOptionsContainer,
} from "./DocumentCreation.styles";
import { SignatureCreditBar } from "../SignatureCreditBar";
import { SocialFooter } from "../SocialFooter";

const DocumentCreation: React.FC = () => {
  // eslint-disable-next-line
  const { createNewDocumentType } = useDocument();
  const { canUse, sessionUserSt } = useSession();

  const handleUploadFileDocumentType = () => {
    createNewDocumentType(EDocumentTypes.RENTING);
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.MANAGE_USER_DOCUMENTS}>
      <StyledContainer data-tesid='automatic-procedures-component'>
        <StyledContentContainer>
          <Box>
            <Typography variant='h4' align='center' color='primary'>
              Bienvenido <br /> a firma electrónica
            </Typography>
            <Typography variant='h6' align='center' color='primary'>
              Genera documentos listos para firmar
            </Typography>
          </Box>
          {(sessionUserSt && sessionUserSt.signature_credits) && (
            <SignatureCreditBar />
          )}
          <StyledOptionsContainer>
            <DocumentOption
              step={1}
              id={EDocumentTypes.BUYING_PROMISE}
              icon={<PaperArrowIcon height={100} />}
              text='Sube tu documento'
            />

            <DocumentOption
              step={2}
              id={EDocumentTypes.NONE}
              icon={<ParticipantsIcon height={100} />}
              text='Agrega participantes'
            />

          </StyledOptionsContainer>
          <Box sx={{ display: 'none' }}>
            <Typography variant='body1' align='center' fontWeight='bold'>
              O adjunta tu propio documento
            </Typography>
          </Box>
          <StyledButtonContainer>
            <Grid container spacing={2}>
              {canUse(Permissions.UPLOAD_USER_DOCUMENT_FILE) && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<CloudUpload />}
                    component="label"
                    onClick={handleUploadFileDocumentType}
                    fullWidth
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Subir archivo
                    </Typography>
                    <FileLoadInput />
                  </Button>
                </Grid>
              )}
              {canUse(Permissions.PURCHASE_USER_SIGNATURE_CREDITS) && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<DrawIcon />}
                    fullWidth
                    component={NavLink}
                    to="/dashboard/v1/signature-credits/purchase-credits"
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Comprar créditos de firmas
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </StyledButtonContainer>
          <SocialFooter />
        </StyledContentContainer>
        <Outlet />
      </StyledContainer>
    </AuthorizedRoute>
  );
};

export default React.memo(DocumentCreation, () => {
  return false;
});
