import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { useSession } from '@/hooks';

import { StyledLinearProgress } from './SignatureCreditBar.styles';

const SignatureCreditBar: FC = () => {
  const { sessionUserSt } = useSession();
  const { signature_credits } = sessionUserSt;

  const MAX = signature_credits.consumed_quantity + signature_credits.remaining_quantity;

  const normalise = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

  return (
    <Box width={{ xs: "100%", md: "70%", lg: "50%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              Créditos Utilizados
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Disponible
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StyledLinearProgress variant="determinate" value={normalise(signature_credits.consumed_quantity, 0, MAX)} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              {signature_credits.consumed_quantity}
            </Typography>
            
            <Typography variant="body2" color="textSecondary">
              {signature_credits.remaining_quantity}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignatureCreditBar;